import { configurationCommon } from './configuration.common';

const commonConfig = configurationCommon({
  apiDomain: 'stg.api.hamperapp.com'
});

export const configuration = {
  ...commonConfig,
  production: true,
  environment: 'staging',
  brazeKey: 'bca242d0-3237-4082-aeec-8d7e43088a2e',
  brazeUrl: 'sdk.iad-07.braze.com',
  hotJarId: 3765219,
  firebaseConfig : {
    apiKey: "AIzaSyBye0dSYgW7MQ9ezMhJCzM1kpr6p-4VQ5U",
    authDomain: "hamperapp-1490443482281.firebaseapp.com",
    databaseURL: "https://hamperapp-1490443482281.firebaseio.com",
    projectId: "hamperapp-1490443482281",
    storageBucket: "hamperapp-1490443482281.appspot.com",
    messagingSenderId: "1078883846776",
    appId: "1:1078883846776:web:f8db75a892c3f38bc7bb8d",
    measurementId: "G-N8TPXHREC7",
    locationId: 'us-central',
  },
  deepLink: 'https://hamperappstg.page.link/',
  deepLinkParametr: '?ibi=RonasIT%2EHamperapp%2Dios2&isi=1129155186&apn=stg%2Eapp%2Ehamperapp%2Ehamperapp&link=https%3A%2F%2Fstg%2Eapp%2Ehamperapp%2Ecom%3Fpromocode%3D',

};
