import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {SeoPage} from '@shared/seo-page';
import {values} from 'lodash';
import {CustomCodeType, SeoTag, SeoTagKeyName} from './types';

@Injectable()
export class SeoService {
  private static DEFAULT_TITLE;

  private metaTags: Map<string, HTMLMetaElement>;
  private customCode: Record<CustomCodeType, ChildNode | null>;

  constructor(
    private metaService: Meta,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.metaTags = new Map();
    this.customCode = {
      head: null,
      'body-start': null,
      'body-end': null
    };
  }

  public initSettings(settings: SeoPage): void {
    this.addTags(settings.seoTags, undefined, settings?.title);
    this.addTags(settings.socialTags, 'property', settings?.title);
    if (settings.socialMedia) {
      this.addTag({name: 'og:image', content: settings.socialMedia.link}, 'property');
    }
    this.setTitle(settings.title);
    this.addCustomCode(settings.codeHead, 'head');
    this.addCustomCode(settings.codeBodyStart, 'body-start');
    this.addCustomCode(settings.codeBodyEnd, 'body-end');
  }

  public addTag(tag: SeoTag, keyName: SeoTagKeyName = 'name', title?: string): void {
    if (tag.name == 'description' || tag.name == 'og:description' || tag.name == 'og:title' || tag.name == 'og:image') {
      if (tag.name == 'og:title') {
        const titleTag = this.document.querySelector(`title`);
        (titleTag as HTMLTitleElement).innerText = title?.length ? title : tag.content

        const metaTag = this.document.querySelector(`[property^=${tag.name}]`);
        (metaTag as HTMLMetaElement).content = tag.content
      } else {
        const metaTag = this.document.querySelector(`[property^=${tag.name}]`);
        (metaTag as HTMLMetaElement).content = tag.content
      }
    } else {
      if (!this.metaTags.has(tag.name)) {
        const element = this.metaService.addTag({[keyName]: tag.name, content: tag.content});
        this.metaTags.set(tag.name, element);
      }
    }
  }

  public addTags(tags: Array<SeoTag>, keyName?: SeoTagKeyName, title?: string): void {
    if (!tags?.length) {
      return;
    }
    for (const tag of tags) {
      this.addTag(tag, keyName, title);
    }
  }

  public resetTags(): void {
    for (const [name, tag] of this.metaTags.entries()) {
      this.metaService.removeTagElement(tag);
      this.metaTags.delete(name);
    }
  }

  public setTitle(title: string): void {
    if (!title?.length) {
      return this.resetTitle();
    }
    this.titleService.setTitle(`${title}`);
  }

  public resetTitle(): void {
    this.titleService.setTitle(SeoService.DEFAULT_TITLE);
  }

  public  addCustomCode(htmlString: string, type: CustomCodeType):void {
     this.removeCustomCode(type, type + '_');
     this.initCustomCode(htmlString, type)
  }

  initCustomCode(htmlString: string, type: CustomCodeType) {
    const template = this.document.createElement('template');
    const html = htmlString?.trim();
    if (!html?.length) {
      return;
    }
    template.innerHTML = html;
    const nodeList = template.content.childNodes;
    let i = 1;
    nodeList.forEach(node => {
      if (node.nodeType !== Node.ELEMENT_NODE) {
        return;
      }
      (node as HTMLElement).setAttribute('id', type + '_' + i++);

      this.customCode[type] = node;

      if (type === 'head') {
        if ((node as HTMLLinkElement).rel == 'canonical') {
          const canonical = this.document.querySelector('[rel^="canonical"]');
          (canonical as HTMLLinkElement).href = (node as HTMLLinkElement).href
        } else {
          this.document.getElementsByTagName('head')[0].appendChild(node);
        }
      }
      if (type === 'body-start') {
        this.document.getElementsByTagName('body')[0].insertBefore(
          node,
          this.document.getElementsByTagName('app-root')[0]
        );
      }
      if (type === 'body-end') {
        this.document.getElementsByTagName('body')[0].appendChild(node);
      }
    })

  }

  public removeCustomCode(type: CustomCodeType, prefix: string): void {
    const customCode = this.document.getElementById(type);
    customCode?.remove();

    let elements = this.document.querySelectorAll('[id^="' + prefix + '"]');
    if (elements.length) {
      elements.forEach(function (element) {
        element.remove();
      });
    }
  }

  public resetCustomCode(): void {
    for (const customCode of values(this.customCode)) {
      customCode?.remove();
    }
  }


  public reset(): void {
    this.resetTags();
    this.resetTitle();
    this.resetCustomCode();
  }
}
